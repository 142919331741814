import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import Logo from '../../assets/img/logo-with-text.png'

import authRep from '../../repository/authRep'
import { sendNotify } from '../../helper/notification'
import { checkLogged } from '../../store/auth/authSlice'

export default function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [togglePass, setTogglePass] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false) 

  const login = () => {
    let payload = {};
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    
    if (email && filter.test(email) && password) {
      setLoading(true)
      payload['email'] = email
      payload['password'] = password
      authRep.login(payload).then(response => {
        if (response) {
          setLoading(false)
          if (response.error) {
            sendNotify('error', response.error?.response?.data?.message)
          } else {
            sendNotify('success', 'Login successfully')
            localStorage.setItem('nl-consular-auth', JSON.stringify(response?.data?.token))
            dispatch(checkLogged())
          }
        }
      })
        .catch(error => ({ error: JSON.stringify(error) }));
    } else {
      if (email == '') {
        sendNotify('error', 'Give your email')
      } else {
        if (!filter.test(email)) {
          sendNotify('error', 'Please provide a valid email')
        }
      }
      if (password == '') {
        sendNotify('error', 'Give your password')
      }
    }
  }

  return (
    <div className='auth-form'>
      <img src={Logo} className='logo' alt="NL - Sendinel" />
      <h2>Login</h2>
      <p>Log in with the data that you entered during your registration.</p>
      <div className="input-box">
        <label htmlFor="email">Email</label>
        <div className='input-field'>
          <input type="email" name='email' id='email' onChange={(e)=>setEmail(e.target.value)} placeholder='Enter your email address' />
        </div>
      </div>
      <div className="input-box">
          <label htmlFor="password">Password</label>
          <div className='input-field'>
            <input type={togglePass ? 'text' : 'password'} name='password' id='password' onChange={(e)=>setPassword(e.target.value)} placeholder='Enter your password' />
            <button className='input-after color-primary' onClick={()=>setTogglePass(!togglePass)}>
              <i className={`far ${togglePass ? 'fa-eye-slash':'fa-eye'}`}></i>
            </button>
          </div>
      </div>
      <button className={`primary-btn mx-auto ${loading ? 'btn-loading': ''}`} onClick={login}>Login 
        <i className={`far ${loading ? 'fa-spinner-third' : 'fa-sign-in'} ms-2 me-0`}></i>
      </button>
      <p className='auth-footer'>Don't have an account <Link to={'/register'}>register here.</Link></p>
    </div>
  )
}
