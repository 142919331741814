import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd';
import { checkLogged } from '../../store/auth/authSlice'
import { sendNotify } from '../../helper/notification'

const { confirm } = Modal;

export default function Navbar() {
  const dispatch = useDispatch()
  let auth = useSelector((state) => state.auth.userDetails)
  // console.log(auth);

  const confirmLogout = () => {
    confirm({
      title: <h5>Confirm Logout</h5>,
      content: <p>Are you sure you want to log out?</p>,
      className: 'custom-confirm',
      centered: true,
      okText: 'Logout',
      onOk() {
        logout();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const logout = () => {
    sendNotify('success', "Logout successfully!")
    localStorage.clear("nl-consular-auth")
    dispatch(checkLogged())
  }

  return (
    <nav className='navbar'>
      {/* <img src={Logo} className='logo' alt="North Lark" /> */}
      <h2>Consular</h2>
      <div className="w-sm-100 d-flex align-items-center justify-content-between justify-content-md-start">
        <div className="profile">
          <img src="https://www.w3schools.com/w3images/avatar3.png" alt="" />
          <div className='d-flex flex-column justify-content-center ms-2 ms-md-3'>
            <h6>{auth?.user}</h6>
            <p>{auth?.email}</p>
          </div>
        </div>
        <button className="nav-btn-secondary ms-md-3" onClick={confirmLogout} title="Logout"><i className="far fa-power-off"></i></button>
      </div>
    </nav>
  )
}
