import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Logo from '../../assets/img/logo-with-text.png'

import authRep from '../../repository/authRep'
import { sendNotify } from '../../helper/notification'

export default function Register() {
  const navigate = useNavigate();

  const [togglePass, setTogglePass] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const signup = () => {
      let payload = {};
      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (email && filter.test(email) && password && confirmPassword) {
          if (password === confirmPassword) {
            setLoading(true)
            payload['email'] = email
            payload['password'] = password
            payload['username'] = name
            authRep.signup(payload).then(response => {
                if (response) {
                  setLoading(false)
                    if (response.error) {
                        sendNotify('error', response.error?.response?.data?.message)
                    } else {
                        sendNotify('success', 'Register successfully, Please Login')
                        navigate("/login");
                    }
                }
            })
                .catch(error => ({ error: JSON.stringify(error) }));
          }else{
            sendNotify('error', 'The given passwords do not match.')
          }
      } else {
          if (email == '') {
              sendNotify('error', 'Give your email')
          } else {
              if (!filter.test(email)) {
                  sendNotify('error', 'Please provide a valid email')
              }
          }
          if (name == '') {
              sendNotify('error', 'Give your name')
          }

          if (password == '') {
              sendNotify('error', 'Give your password')
          }
      }
  }

  return (
    <div className='auth-form'>
      <img src={Logo} className='logo' alt="NL - Sendinel" />
      <h2>Register</h2>
      <p>To create your account, enter the below inputs.</p>
      <div className="input-box">
        <label htmlFor="email">Username</label>
        <div className='input-field'>
          <input type="text" name='email' id='email' placeholder='Enter your username' onChange={(e)=>setName(e.target.value)} />
        </div>
      </div>
      <div className="input-box">
        <label htmlFor="email">Email</label>
        <div className='input-field'>
          <input type="email" name='email' id='email' placeholder='Enter your email address' onChange={(e)=>setEmail(e.target.value)} />
        </div>
      </div>
      <div className="input-box">
          <label htmlFor="password">Password</label>
          <div className='input-field'>
            <input type={togglePass ? 'text' : 'password'} name='password' id='password' placeholder='Enter your password' onChange={(e)=>setPassword(e.target.value)} />
            <button className='input-after color-primary' onClick={()=>setTogglePass(!togglePass)}>
              <i className={`far ${togglePass ? 'fa-eye-slash':'fa-eye'}`}></i>
            </button>
          </div>
      </div>
      <div className="input-box">
        <label htmlFor="password">Confirm Password</label>
        <input type='password' name='password' id='password' placeholder='Enter your password' onChange={(e)=>setConfirmPassword(e.target.value)} />
      </div>
      <button className={`primary-btn mx-auto ${loading ? 'btn-loading': ''}`} onClick={signup}>Register 
        <i className={`far ${loading ? 'fa-spinner-third' : 'fa-user-plus'} ms-2 me-0`}></i>
      </button>
      <p className='auth-footer'>Already have an account <Link to={'/login'}>login here.</Link></p>
    </div>
  )
}
